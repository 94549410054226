import React from 'react'
import Select, { components } from 'react-select'
import colors from 'src/assets/styles/colors'
import typographySizes from 'src/assets/styles/typographySizes'
import arrow from 'src/assets/svg/estimate-arrow.svg'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={arrow} alt="arrow" />
    </components.DropdownIndicator>
  )
}

const CustomSelect = props => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: `1rem solid ${colors.black}`,
      color: state.isSelected ? ` ${colors.black}` : ` ${colors.black}`,
      backgroundColor: state.isSelected
        ? ` ${colors.secColor}`
        : ` ${colors.white}`,
      fontSize: typographySizes.s,
    }),
    control: (provided, state) => {
      const height = '60rem'
      const backgroundColor = colors.white
      const borderRadius = 'none'
      const border = 'none'

      return {
        ...provided,
        height,
        backgroundColor,
        borderRadius,
        border,
      }
    },
    menuList: (provided, state) => {
      const backgroundColor = colors.white
      const borderRadius = 'none'
      const border = 'none'
      const padding = '0'
      const borderBottom = `solid 4rem ${colors.black}`
      return {
        ...provided,
        padding,
        backgroundColor,
        borderRadius,
        border,
        borderBottom,
      }
    },
    dropdownIndicator: (provided, state) => {
      const paddingRight = '15rem'

      return { ...provided, paddingRight }
    },
    indicatorSeparator: (provided, state) => {
      const display = 'none'

      return { ...provided, display }
    },
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'
      const fontSize = typographySizes.s

      return { ...provided, opacity, transition, fontSize }
    },
  }
  return (
    <Select
      isSearchable={false}
      name={props.name}
      components={{ DropdownIndicator }}
      options={props.options[0]}
      styles={customStyles}
      defaultValue={props.options[0][0]}
      onChange={props.onChange}
    />
  )
}

export default CustomSelect
