import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import AnimatedNumber from 'animated-number-react'
import Div100vh from 'react-div-100vh'
import colors from 'src/assets/styles/colors'
import distances from 'src/assets/styles/distances'
import typographySizes from 'src/assets/styles/typographySizes'
import mediaQuery from 'src/assets/styles/mediaQuery'
import Seo from 'src/components/global/Seo.js'
import MainWrapper from 'src/components/global/MainWrapper.js'
import Lines from 'src/components/global/lines/Lines'
import TextBarAnimation from '/src/components/global/TextBarAnimationBig.js'

import CustomSelect from 'src/components/Estimate/CustomSelect.js'

import PopUpInfo from 'src/components/Estimate/PopUpInfo.js'

const StyledMain = styled.main`
  background-color: ${colors.mainColor};
  width: 100vw;
  padding-top: 70rem;
  ${MainWrapper} {
    display: flex;
    justify-content: space-between;

    form {
      font-size: 10rem;
      color: #fff;
      label,
      input {
        font-size: 10rem;
        color: #fff;
        border-radius: 0;
        -webkit-appearance: none;
      }
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    padding-top: 125rem;
  }
`

const StyledForm = styled.form`
  width: calc(50% - 40rem);
  margin-left: 20rem;
  padding-bottom: 120rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% - 40rem);
    padding-bottom: ${distances.mBottomPage / 2}rem;
  }
`

const StyledLabel = styled.label`
  position: relative;
  color: ${colors.white};
  font-size: ${typographySizes.s}rem !important;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: ${distances.barW * 2}rem;

  display: inline-block;
  width: 100%;
  margin-bottom: 25rem;
  margin-top: 50rem;
  display: flex;
  align-items: center;
  ::before {
    content: '';
    width: ${distances.barW}rem;
    height: calc(100% + ${distances.barW}rem);
    background-color: ${colors.white};
    position: absolute;
    left: 0;
    top: -${distances.barW / 2}rem;
  }
`

const StyledCheckmark = styled.span`
  display: block;
  position: relative;
  width: 30rem;
  height: 30rem;
  vertical-align: middle;
  margin-right: 20rem;
  cursor: pointer;
  border: solid 1rem #fff;
  background-color: ${colors.white};
  flex-shrink: 0;

  ::before {
    content: '';
    position: absolute;
    width: 0%;
    left: 50%;
    top: 50%;
    height: 6rem;
    transform: translate(-50%, -50%) rotate(45deg);
    background-color: ${colors.black};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  ::after {
    content: '';
    position: absolute;
    width: 0%;
    left: 50%;
    top: 50%;
    height: 6rem;
    transform: translate(-50%, -50%) rotate(-45deg);
    background-color: ${colors.black};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`

const LabelWrapper = styled.label`
  display: flex;
  margin-bottom: 20rem;
  font-size: ${typographySizes.s}rem !important;
  line-height: 30rem;
  text-transform: uppercase;
  margin-top: 10rem;

  :first-of-type {
    margin-top: 50rem;
  }

  input {
    display: none;
    border-radius: 0;
    -webkit-appearance: none;
  }

  input:checked + ${StyledCheckmark} {
    ::after {
      width: 80%;
    }
    ::before {
      width: 80%;
    }
  }
`

const CheckmarkDivider = styled.span`
  font-size: ${typographySizes.s}rem !important;
  color: ${colors.back};
  display: inline-block;
  margin: 0 10rem;
`

const StyledTextarea = styled.textarea`
  font-size: ${typographySizes.s}rem !important;
  color: ${colors.back};
  width: calc(100% - 40rem);
  border: none;
  outline: none;
  padding: 20rem;
  -webkit-appearance: none;
  border-radius: 0;
`

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* background-color: blue; */
  position: relative;
`

const StyledDiv100vh = styled(Div100vh)`
  position: relative;
  /* background-color: red; */
  background-color: blue;
  display: none;
  top: 0;

  @media (max-width: ${mediaQuery.tablet}) {
    top: -270rem;
    display: block;
  }
`

const SummaryWrapperW = styled.div`
  position: sticky;
  top: 200rem;
  right: 100rem;
  align-self: flex-start;
  z-index: 5;
  margin-top: 150rem;
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    /* position: fixed; */
    top: 240rem;

    margin-top: 0rem;
    margin-bottom: -70rem;

    /* transform: translateY(calc(-100% - 50rem)) rotate(-3deg); */
    right: 20rem;
  }
`

const SummaryWrapper = styled.div`
  padding: 8rem 10rem 15rem 10rem;
  background-color: ${colors.black};
  /* position: sticky; */
  position: absolute;
  bottom: 0;
  right: 0;
  margin-top: 50rem;
  transform: rotate(-3deg);
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
  }
  &.destkop {
    display: block;
    @media (max-width: ${mediaQuery.tablet}) {
      display: none;
    }
  }
`

const Summary = styled.span`
  font-size: ${typographySizes.m}rem;
  color: ${colors.mainColor};
  font-weight: 700;
  display: inline-block;
  text-transform: uppercase;
  min-width: 320rem;
  text-align: right;
  span {
    font-size: ${typographySizes.m}rem;
    color: ${colors.mainColor};
    margin-right: 10rem;
    margin-left: 10rem;
    display: inline-block;
    font-weight: 700;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: ${typographySizes.mMobile}rem;
    }
  }
`
const SummarySubTitle = styled.span`
  font-size: ${typographySizes.s}rem;
  color: ${colors.white};
  display: block;
  width: 100%;
  text-align: right;
  line-height: 1;
`
const PersonalWrapper = styled.div`
  background-color: ${colors.black};
  margin-top: 50rem;
  padding-bottom: 20rem;
`

const PersonalTitle = styled.h3`
  font-size: ${typographySizes.m}rem;
  text-transform: uppercase;
  color: ${colors.secColor};
  line-height: 1;
  padding: 20rem;
  padding-bottom: 0;
`

const PersonalLabel = styled.label`
  position: relative;
  color: ${colors.white};
  font-size: ${typographySizes.s}rem !important;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: ${distances.barW * 2}rem;

  display: inline-block;

  margin-bottom: 25rem;
  margin-top: 50rem;
  margin-left: 20rem;

  ::before {
    content: '';
    width: ${distances.barW}rem;
    height: calc(100% + ${distances.barW}rem);
    background-color: ${colors.white};
    position: absolute;
    left: 0;
    top: -${distances.barW / 2}rem;
  }
`
const PersonalInput = styled.input`
  width: calc(100% - 40rem);
  padding: 20rem;
  background-color: ${colors.white};
  border: none;
  outline: none;
  color: ${colors.black} !important;
  font-size: ${typographySizes.s}rem !important;
  border-radius: 0;
  -webkit-appearance: none;
`
const PersonaCheckmarkWrapper = styled.label`
  display: flex;
  margin-bottom: 20rem;
  font-size: ${typographySizes.s}rem !important;
  line-height: 1.2;

  margin-top: 10rem;
  margin-left: 20rem;
  margin-right: 20rem;
  margin-top: 30rem;
  :first-of-type {
    margin-top: 50rem;
  }

  input {
    /* display: none; */
    position: absolute;
    border-radius: 0;
    -webkit-appearance: none;
  }

  input:checked + ${StyledCheckmark} {
    ::after {
      width: 80%;
    }
    ::before {
      width: 80%;
    }
  }
`

const ButtonMask = styled.span`
  font-size: ${typographySizes.s}rem !important;
  position: absolute;
  height: calc(100% - 40rem);
  width: calc(100% - 40rem);
  left: 0;
  top: 0;
  color: ${colors.secColor};
  padding: 20rem;
  background-color: ${colors.black};
  clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
`

const FormButton = styled.button`
  font-size: ${typographySizes.s}rem !important;
  background-color: ${colors.white};
  color: ${colors.mainColor};
  font-weight: 700;
  text-transform: uppercase;
  padding: 20rem;
  margin-top: 50rem;
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  cursor: pointer;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 200rem;
  }
  :hover {
    ${ButtonMask} {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`

const SummaryInput = styled.div`
  display: none;
`

class FormComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      baseTotal: 200,
      total: 200,
      positions: [],
    }
  }

  componentDidMount() {
    console.log(this.props.startingPrice)
    this.setState({ baseTotal: this.props.startingPrice })
    this.setState({ total: this.props.startingPrice })
    this.props.estimate.forEach(pos => {
      this.state.positions.push({
        Label: pos.Label,
        Val: pos.List[0].Price,

        Checkboxes: new Array(pos.List.length).map((_, i) => !i),
      })
    })
    let newTotal = this.props.startingPrice

    this.state.positions.forEach(pos => {
      newTotal += parseInt(pos.Val)
    })
    this.setState({ total: newTotal })
  }

  handleTotal = e => {
    var index = e.nativeEvent.target.selectedIndex

    this.state.positions[e.nativeEvent.target.id].Val =
      e.nativeEvent.target[index].dataset.price

    let newTotal = this.state.baseTotal

    this.state.positions.forEach(pos => {
      newTotal += parseInt(pos.Val)
    })

    this.setState({ total: newTotal })
  }

  handleTotalNew = (e, index) => {
    const str = e.value + ''
    const slug = str.split('/').pop()
    const price = slug.substr(0, slug.indexOf('zł'))

    this.state.positions[index].Val = price
    let newTotal = this.state.baseTotal
    this.state.positions.forEach(pos => {
      newTotal += parseInt(pos.Val)
    })

    this.setState({ total: newTotal })
  }

  onChange(e, changedIndex, index) {
    this.setState(
      state => {
        const list = [...state.positions]
        const chekboxs = [...state.positions[index].Checkboxes]
        const newList = []
        const newChekbox = []
        const CurenPos = state.positions[index]
        const CurenPosVal = state.positions[index].Val
        const isCheked = e.target.checked ? true : false

        const newVal = e.target.checked
          ? CurenPosVal + parseInt(e.target.dataset.price)
          : CurenPosVal - parseInt(e.target.dataset.price)

        chekboxs.map((_, i) =>
          i === changedIndex
            ? newChekbox.push(isCheked)
            : newChekbox.push(chekboxs[i]),
        )

        CurenPos.Val = parseInt(newVal)
        CurenPos.Checkboxes = newChekbox

        list.map((_, i) =>
          i === index ? newList.push(CurenPos) : newList.push(list[i]),
        )

        return {
          positions: newList,
        }
      },
      () => {
        let newTotal = parseInt(this.state.baseTotal)

        this.state.positions.forEach(pos => {
          newTotal += parseInt(pos.Val)
        })

        this.setState({ total: newTotal })
      },
    )
  }

  formatValue = value => value.toFixed(0)

  render() {
    return (
      <FormWrapper>
        <StyledForm action="https://submit-form.com/V15TmU6z" id="form">
          {this.props.estimate.map((pos, index) => (
            <>
              <StyledLabel for={pos.Label}>
                {pos.Label}{' '}
                {pos.Additional_information ? (
                  <PopUpInfo info={pos.Additional_information} />
                ) : null}{' '}
              </StyledLabel>

              {pos.Multiple_choice ? (
                <>
                  {pos.List.map((posEl, i) => (
                    <LabelWrapper>
                      <input
                        key={i}
                        name={`${pos.Label} / ${posEl.Name}`}
                        type="checkbox"
                        data-price={posEl.Price}
                        checked={
                          this.state.positions.length > 0
                            ? this.state.positions[index].Checkboxes[i]
                            : i === 0 && false
                        }
                        onChange={
                          e =>
                            this.onChange(
                              e,
                              i,
                              index,
                            ) /* notice passing an index. we will use it */
                        }
                      />
                      <StyledCheckmark />
                      {posEl.Name} <CheckmarkDivider> / </CheckmarkDivider>
                      {posEl.Price} zł
                    </LabelWrapper>
                  ))}
                </>
              ) : (
                <CustomSelect
                  name={pos.Label}
                  onChange={e => this.handleTotalNew(e, index)}
                  index={index}
                  options={[
                    pos.List.map(posEl => {
                      return {
                        value: `${posEl.Name} / ${posEl.Price}zł`,
                        label: `${posEl.Name} / ${posEl.Price}zł`,
                      }
                    }),
                  ]}
                />
              )}
            </>
          ))}

          {this.props.moreInfo.map((pos, index) => (
            <>
              <StyledLabel for={pos.Label}>
                {pos.Label}{' '}
                {pos.Additional_information ? (
                  <PopUpInfo info={pos.Additional_information} />
                ) : null}{' '}
              </StyledLabel>
              <StyledTextarea
                rows="4"
                cols="50"
                form="form"
                placeholder={pos.Placeholder}
                name={pos.Label}
              ></StyledTextarea>
            </>
          ))}
          <PersonalWrapper>
            <PersonalTitle>
              Wprowadź <br /> swoje dane
            </PersonalTitle>
            <PersonalLabel for="Name"> Imię i Nazwisko</PersonalLabel>
            <PersonalInput
              type="text"
              id="Name"
              name="Name"
              required
              placeholder="Podaj imię i Nazwisko"
            ></PersonalInput>
            <PersonalLabel for="email"> Adres e-mail</PersonalLabel>
            <PersonalInput
              type="text"
              id="email"
              name="email"
              required
              placeholder="Podaj adres e-mail"
            ></PersonalInput>
            <PersonalLabel for="telefon"> Telefon</PersonalLabel>
            <PersonalInput
              type="text"
              id="Telefon"
              name="Telefon"
              required
              placeholder="Podaj telefon"
            ></PersonalInput>
            <PersonaCheckmarkWrapper>
              <input name="Gdpr" type="checkbox" required />
              <StyledCheckmark />
              Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z
              ustawą o ochronie danych osobowych w związku z realizacją
              zgłoszenia. Podanie danych jest dobrowolne, ale niezbędne do
              przetworzenia zapytania. Zostałem /am poinformowany /a, że
              przysługuje mi prawo dostępu do swoich danych, możliwości ich
              poprawiania, żądania zaprzestania ich przetwarzania.
              Administratorem danych osobowych jest Nobocoto Sp. z o.o. ul.
              Kaliskiego 15A, 01-476 Warszawa.
            </PersonaCheckmarkWrapper>
          </PersonalWrapper>
          <SummaryInput>
            <PersonalInput
              type="text"
              name="Cała kwota"
              value={`${this.state.total} zł`}
            ></PersonalInput>
          </SummaryInput>

          <FormButton type="submit">
            Wyślij formularz do weryfikacji
            <ButtonMask> Wyślij formularz do weryfikacji</ButtonMask>
          </FormButton>
        </StyledForm>
        <SummaryWrapperW>
          <StyledDiv100vh>
            <SummaryWrapper>
              <Summary>
                {' '}
                SUMA:
                <AnimatedNumber
                  value={this.state.total}
                  formatValue={this.formatValue}
                  duration={500}
                />
                zł
              </Summary>
              <SummarySubTitle>Podana kwota to cena brutto.</SummarySubTitle>
            </SummaryWrapper>
          </StyledDiv100vh>
          <SummaryWrapper className="destkop">
            <Summary>
              SUMA:
              <AnimatedNumber
                value={this.state.total}
                formatValue={this.formatValue}
                duration={500}
              />
              zł
            </Summary>
            <SummarySubTitle>Podana kwota to cena brutto.</SummarySubTitle>
          </SummaryWrapper>
        </SummaryWrapperW>
      </FormWrapper>
    )
  }
}

const WycenRealizacje = ({ data }) => {
  return (
    <>
      <Seo
        title={
          data.strapiPageEstimateRealizations.SEO
            ? data.strapiPageEstimateRealizations.SEO.Title
            : null
        }
        description={
          data.strapiPageEstimateRealizations.SEO
            ? data.strapiPageEstimateRealizations.SEO.Description
            : null
        }
        image={
          data.strapiPageEstimateRealizations.SEO &&
          data.strapiPageEstimateRealizations.SEO.Img
            ? data.strapiPageEstimateRealizations.SEO.Img.localFile.publicURL
            : null
        }
      />

      <StyledMain>
        <TextBarAnimation>wyceń swój mix</TextBarAnimation>

        <MainWrapper>
          <Lines leftMargin center rightCenter right rightMargin />
          <FormComponent
            moreInfo={data.strapiPageEstimateRealizations.More_Information}
            estimate={data.strapiPageEstimateRealizations.Estimate_realizations}
            startingPrice={data.strapiPageEstimateRealizations.Starting_price}
          />
        </MainWrapper>
      </StyledMain>
    </>
  )
}

export const WycenRealizacjeQuery = graphql`
  query WycenRealizacjeQuery {
    strapiPageEstimateRealizations {
      SEO {
        Description
        Img {
          localFile {
            publicURL
          }
        }
        Title
      }
      Starting_price
      Estimate_realizations
      More_Information
    }
  }
`

export default WycenRealizacje
