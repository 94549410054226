import React, { useState, useRef, useEffect } from 'react'
import colors from 'src/assets/styles/colors'
import typographySizes from 'src/assets/styles/typographySizes'
import infoBlack from 'src/assets/svg/info_black.svg'
import styled from 'styled-components'
import mediaQuery from 'src/assets/styles/mediaQuery'

const Wrapper = styled.div`
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    position: initial;
  }
`

const StyledImg = styled.img`
  height: 15rem;

  cursor: pointer;
  padding: 0rem 10rem;
`

const PopUp = styled.div`
  padding: 20rem;
  background-color: ${colors.black};
  position: absolute;
  bottom: 0;
  left: 30rem;
  transform: translate(0%, calc(100% + 10rem));
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  width: auto;
  visibility: none;
  width: 350rem;
  max-height: 200rem;
  overflow-y: scroll;
  /* width */
  ::-webkit-scrollbar {
    width: 6rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colors.black};
    /* border: 4rem ${colors.black} solid; */
    border-right: 4rem ${colors.black} solid;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colors.mainColor};
    border-top: 4rem ${colors.black} solid;
    border-right: 4rem ${colors.black} solid;
    border-bottom: 4rem ${colors.black} solid;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &.open {
    opacity: 1;
    transform: translate(0%, calc(100%));
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    visibility: visible;
    pointer-events: all;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    left: -10rem;
    bottom: -20rem;
    width: calc(100% - 45rem);
  }
`

const PopUpText = styled.p`
  color: ${colors.white};
  font-size: ${typographySizes.s}rem;
  font-weight: 400;
`

const PopUpInfo = ({ info }) => {
  const wrapperRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const openPop = () => {
    setIsOpen(isOpen ? false : true)
  }

  useEffect(() => {
    const checkIfClickedOutside = e => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isOpen &&
        wrapperRef.current &&
        !wrapperRef.current.contains(e.target)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', checkIfClickedOutside)

    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [isOpen])
  //   useOutsideAlerter(wrapperRef, isOpen, setIsOpen)

  return (
    <Wrapper>
      <StyledImg onClick={openPop} src={infoBlack} />
      <PopUp className={isOpen && 'open'} ref={wrapperRef}>
        <PopUpText>{info}</PopUpText>
      </PopUp>
    </Wrapper>
  )
}

export default PopUpInfo
